"use client";

import React, { useEffect, useState } from "react";
import { Box, Link, Button } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import CloseIcon from "@mui/icons-material/Close";

const cookieConsentName = "pivotCookiesConsent";
const privacyPolicyUrl =
  "https://www.healthcare.com/privacy-policy#additional-information-about-our-data-collection-and-sharing-practices";

const getCookieConsent = () => {
  const getCookieValue = (name: string) => {
    const regex = new RegExp(`(^| )${name}=([^;]+)`);
    const match = document.cookie.match(regex);
    if (match) {
      return match[2];
    }

    return "";
  };

  return getCookieValue(cookieConsentName) === "true";
};

const setCookieConsent = () => {
  document.cookie = `${cookieConsentName}=true; path=/; max-age=31536000`;
};

export default function CookiesConsentBanner(): React.JSX.Element | null {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (!getCookieConsent()) {
      setOpen(true);
    }
  }, []);

  const handleClose = () => {
    setCookieConsent();
    setOpen(false);
  };

  if (!open) {
    return null;
  }

  return (
    <Box
      sx={{
        maxWidth: "480px",
        width: "100%",
        height: "104px",
        backgroundColor: "rgba(0,134,231,1)",
        position: "fixed",
        bottom: "20px",
        right: "20px",
        zIndex: 10,
        borderRadius: "0.25rem",
        color: "#fff",
        padding: "1rem",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <InfoIcon
        sx={{
          marginRight: "1rem",
        }}
      />
      <Box>
        We use cookies to improve your experience on our site. By using our site
        you consent cookies.
        <Link
          sx={{ color: "#fff", fontWeight: "bold", display: "block" }}
          href={privacyPolicyUrl}
          target="_blank"
        >
          Learn more
        </Link>
      </Box>
      <Button
        data-testid="close-button"
        onClick={handleClose}
        sx={{
          margin: 0,
          padding: 0,
          backgroundColor: "transparent",
          "&:hover": {
            backgroundColor: "rgba(0,134,231,0.5)",
          },
        }}
      >
        <CloseIcon
          sx={{
            color: "#fff",
          }}
        />
      </Button>
    </Box>
  );
}
